<template>
    <div>
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <CalculatorSelect :form="form" label="area" :data="area" font="地区"></CalculatorSelect>

            <CalculatorSelect
                :form="form"
                label="project"
                :data="project"
                font="赔偿项目"
            ></CalculatorSelect>

            <template v-if="form.project == 'B1'">
                <CalculatorSelect
                    :form="form"
                    label="disabilityLevel"
                    :data="disabilityLevel"
                    font="伤残等级"
                ></CalculatorSelect>
            </template>

            <CalculatorInputNumber
                :form="form"
                label="victimsAge"
                suffix="岁"
                font="受害者年龄"
                :maxLength="3"
            ></CalculatorInputNumber>

            <template v-if="form.project == 'B1'">
                <CalculatorInputNumber
                    :form="form"
                    label="InHospital"
                    suffix="天"
                    font=" 住院天数"
                    :maxLength="5"
                ></CalculatorInputNumber>

                <CalculatorInputNumber
                    :form="form"
                    label="medicalFees"
                    suffix="元"
                    font=" 医疗费"
                    :maxLength="10"
                ></CalculatorInputNumber>

                <CalculatorInputNumber
                    :form="form"
                    label="auxiliaryInstrumentFee"
                    suffix="元"
                    font=" 辅助器具费"
                    :maxLength="10"
                ></CalculatorInputNumber>
            </template>
        </a-form-model>
        <ButtonView @start="start" @reset="reset"></ButtonView>
        <Info :mark="mark ? mark.annotation : ''"></Info>
        <CalculatorResultClassification :result="result"></CalculatorResultClassification>
    </div>
</template>

<script>
    import CalculatorSelect from '@/components/PC/calculator/calculator-select.vue'
    import CalculatorInputNumber from '@/components/PC/calculator/calculator-inputNumber.vue'
    import ButtonView from '@/components/PC/calculator/button-view.vue'
    import CalculatorResultClassification from '@/components/PC/calculator/calculator-result-classification.vue'
    import Info from '@/components/PC/calculator/info.vue'
    // import { disabilityLevel } from '../data/disabilityLevel'
    // import { area } from '../data/area'
    // import { intervalCalculation } from './intervalCalculation'
    import { apiList } from '@/api/apiList'

    import * as Server from '@/api/server.js'

    // 赔偿项目 - 残疾赔偿金
    const DISABILITY_RESULT_MAP = [
        '残疾赔偿金',
        '住院伙食补助费',
        '精神损害抚慰金',
        '医疗费',
        '辅助器具费',
    ]
    // 赔偿项目 - 丧葬费
    const FUNERAL_RESULT_MAP = ['丧葬费', '死亡赔偿金', '精神损害抚慰金']

    const MODULEID_MAP = {
        Damage: '2',
        Traffic: '4',
    }

    export default {
        name: 'damage',
        components: {
            CalculatorSelect,
            ButtonView,
            Info,
            CalculatorInputNumber,
            CalculatorResultClassification,
        },
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 },
                form: {
                    area: '',
                    disabilityLevel: '', //伤残等级
                    victimsAge: '', //受害者年龄
                    project: '', //赔偿项目
                    medicalFees: '', //医疗费
                    InHospital: '', //住院天数
                    auxiliaryInstrumentFee: '', //辅助器具费
                },
                disabilityLevel: [],
                area: [],
                result: [],
                project: [],
                mark: '',
                // '本计算器的法律依据是《中华人民共和国民法典》和《最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释》等，所计算的损失赔偿数额并不代表最终的结果，具体的损失赔偿数额双方可以协商确定，也可以由法院进行判决，预估结果仅供参考，实际费用以当地实际情况为准！',
            }
        },
        watch: {
            'form.project'(newVal) {
                this.form = {
                    area: this.form.area,
                    disabilityLevel: this.form.disabilityLevel, //伤残等级
                    victimsAge: '', //受害者年龄
                    numberOfCommonResources: '', //共同抚养人数
                    rumorfulAge: '', //被抚养人年龄
                    project: newVal, //赔偿项目
                    medicalFees: '', //医疗费
                    InHospital: '', //住院天数
                    auxiliaryInstrumentFee: '', //辅助器具费
                }
                this.result = []
            },
        },
        created() {
            this.getParams()
        },
        methods: {
            async getParams() {
                //请求数据
                let data = {
                    method: 'GET',
                    url:
                        apiList.getCalculatorParams +
                        `/${MODULEID_MAP[this.$route.query.moduleId]}`,
                    config: {},
                    loading: true,
                }
                Server.Axios(data)
                    .then((res) => {
                        // console.log(res.data.returnData, '请求返回的值')
                        if (!res.data.returnData.length) {
                            this.$message.error('服务出错，请稍后重试')
                            return false
                        }

                        // 地区
                        this.area = res.data.returnData
                            .filter((i) => i.paramEn === 'A')
                            .map((i) => {
                                return i.calculatorParamDropDownBoxList.map((i) => ({
                                    ...i,
                                    id: i.paramEn,
                                    name: i.paramCn,
                                    value: i.paramEn,
                                }))
                            })
                            .flat()
                        this.form.area = this.area.length > 0 ? this.area[0].id : ''

                        // 赔偿项目
                        this.project = res.data.returnData
                            .filter((i) => i.paramEn === 'B')
                            .map((i) => {
                                return i.calculatorParamDropDownBoxList.map((i) => ({
                                    ...i,
                                    id: i.paramEn,
                                    name: i.paramCn,
                                    value: i.paramEn,
                                }))
                            })
                            .flat()
                        this.form.project = this.project.length > 0 ? this.project[0].id : ''

                        // 伤残等级
                        this.disabilityLevel = res.data.returnData
                            .filter((i) => i.paramEn === 'E')
                            .map((i) => {
                                return i.calculatorParamDropDownBoxList.map((i) => ({
                                    ...i,
                                    id: i.paramEn,
                                    name: i.paramCn,
                                    value: i.paramEn,
                                }))
                            })
                            .flat()
                        this.form.disabilityLevel =
                            this.disabilityLevel.length > 0 ? this.disabilityLevel[0].id : ''
                        // console.log(this.form.area, this.form.project, this.form.disabilityLevel)
                        this.mark = res.data.returnData.find((i) => i.paramEn === 'Z')
                    })
                    .catch((err) => {
                        this.$message.error(err.data.returnInfo || '服务出错，请稍后重试')
                    })
            },
            start(callback) {
                for (let item in this.form) {
                    if (!this.form[item]) {
                        this.form[item] = 0
                    } else {
                        // this.form[item] = Number(this.form[item])
                    }
                }
                // let disabilityLevelData = disabilityLevel.filter(
                //     (item) => item.id == this.form.disabilityLevel
                // )

                // let areaData = area.filter((item) => item.id == this.form.area)

                // this.result = intervalCalculation(
                //     this.form,
                //     disabilityLevelData[0],
                //     areaData[0].data
                // )
                // this.result.splice(0, 0)

                this.calculator()
                callback()
            },
            randerMoneyResult(_arr = [], resData = []) {
                return _arr.map((i) => {
                    const resultMoney = resData.find((item) => i === item.paramCn)

                    return {
                        name: i,
                        money: resultMoney ? resultMoney.result : '0.00',
                    }
                })
            },
            totalsMoneyResult(_arr) {
                return _arr.reduce((acc, cur) => acc + parseFloat(cur.money), 0)
            },
            calculator() {
                if (!this.disabilityLevel.length || !this.area.length || !this.project.length) {
                    this.$message.error('计算错误，请重试')
                    return false
                }

                let disabilityLevelData = this.disabilityLevel.find(
                    (item) => item.id == this.form.disabilityLevel
                )
                let areaData = this.area.find((item) => item.id == this.form.area)
                let projectData = this.project.find((item) => item.id == this.form.project)

                Server.Axios({
                    method: 'POST',
                    url:
                        apiList.calculateForRenshen +
                        `/${MODULEID_MAP[this.$route.query.moduleId]}`,
                    config: {
                        data: {
                            A: areaData.value || '',
                            B: projectData.value || '',
                            C: this.form.victimsAge,
                            D: this.form.InHospital,
                            E: disabilityLevelData.value || '',
                            F: this.form.medicalFees,
                            G: this.form.auxiliaryInstrumentFee,
                        },
                    },
                    loading: true,
                }).then((res) => {
                    if (!res.data.returnData.length) {
                        this.$message.error('计算错误，请重试')
                        return false
                    }

                    if (this.form.project == 'B1') {
                        const resMoneyResult = this.randerMoneyResult(
                            DISABILITY_RESULT_MAP,
                            res.data.returnData
                        )
                        this.result = [
                            ...resMoneyResult,
                            {
                                name: '总计：',
                                money: (this.totalsMoneyResult(resMoneyResult) || 0).toFixed(2),
                            },
                        ]
                    }
                    if (this.form.project == 'B2') {
                        const resMoneyResult = this.randerMoneyResult(
                            FUNERAL_RESULT_MAP,
                            res.data.returnData
                        )
                        this.result = [
                            ...resMoneyResult,
                            {
                                name: '总计：',
                                money: (this.totalsMoneyResult(resMoneyResult) || 0).toFixed(2),
                            },
                        ]
                    }
                })
            },
            reset() {
                this.form = {
                    area: this.form.area,
                    disabilityLevel: this.form.disabilityLevel, //伤残等级
                    victimsAge: '', //受害者年龄
                    numberOfCommonResources: '', //共同抚养人数
                    rumorfulAge: '', //被抚养人年龄
                    project: this.form.project, //赔偿项目
                    medicalFees: '', //医疗费
                    InHospital: '', //住院天数
                    auxiliaryInstrumentFee: '', //辅助器具费
                }
                this.result = []
            },
        },
    }
</script>
